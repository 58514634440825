.container {
  padding: 0 1rem;
  max-height: 100vh;
}

.main {
  min-height: 100vh;
  padding: 0.5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1;
  font-size: 3rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.grid {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  min-height: calc(100vh - 10rem);
}
.editor {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex-grow: 1;
}
.resultContainer {
  display: flex;
  align-items: flex-start;
  overflow: auto;
  max-height: 400px;
  flex-direction: column;
}

.footerText {
  padding: 0 8px;
  text-decoration: underline;
}

.card {
  margin: 1rem;
  padding: 1rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
  cursor: pointer;
  background-color: rgb(107, 107, 107);
}

.card:hover,
.card:focus,
.card:active {
  color: #eaeaea;
  border-color: #eaeaea;
}

.card h2 {
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.statusSuccess {
  padding: 4px;
  background: rgba(97, 206, 97, 0.5);
  border: 1px solid #61ce61;
  border-radius: 8px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statusFail {
  padding: 4px;
  background: rgba(255, 85, 85, 0.5);
  border: 1px solid #ff5555;
  border-radius: 8px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result {
  display: flex;
  padding: 4px 0;
  justify-content: center;
  align-items: center;
}
.path {
  padding: 0 8px;
}
@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .logo img {
    filter: invert(1);
  }
}
